<template>
  <div id="rulePreview">
    <div class="model_container">
      <div class="title">
        {{ baseForm.priceName ? baseForm.priceName : "未命名" }}
      </div>
      <ul>
        <li>
          <span>计费周期</span>
          <span>自定义{{ periodHours ? periodHours : 24 }}小时</span>
        </li>
        <li>
          <span>停车时长</span>
          <span>收费标准</span>
        </li>
        <li>
          <span
            >{{ chargeForm.freeDuration ? chargeForm.freeDuration : "--"
            }}{{ chargeForm.freeUnit }}内</span
          >
          <span>免费</span>
        </li>
        <li>
          <span>单日单次最高收费</span>
          <span
            >{{
              chargeForm.highestCharge >= 0 ? chargeForm.highestCharge : "--"
            }}元</span
          >
        </li>
        <li>
          <span>单日多次最高收费</span>
          <span
            >{{
              chargeForm.vehicleTopCharge >= 0
                ? chargeForm.vehicleTopCharge
                : "--"
            }}元</span
          >
        </li>
      </ul>
    </div>
    <div class="model_container" v-if="priceType === '组合收费'">
      <div class="title">
        {{ baseForm.countWithArrival === "是" ? "进场后24小时" : "自然日" }}
      </div>
    </div>
    <div v-if="priceType === '组合收费'" class="model_container">
      <div v-for="(item, index) of detailForm.priceDetailList" :key="index">
        <div class="title">
          {{ item.timeStart.slice(0, 2) + ":" + item.timeStart.slice(2) }}-{{
            item.timeEnd.slice(0, 2) + ":" + item.timeEnd.slice(2)
          }}
        </div>
        <ul v-if="item.priceType === '按时长收费'">
          <li>
            <span>该时段最高收费</span>
            <span
              >{{
                item.highestCharge >= 0 ? item.highestCharge : "--"
              }}元封顶</span
            >
          </li>
          <li v-for="items of item.priceUnitList" :key="items.id">
            <span
              >{{ items.fromTime >= 0 ? items.fromTime : "--" }}~{{
                items.toTime ? items.toTime : "--"
              }}{{ items.countUnit }}</span
            >
            <span
              >{{ items.chargeAmount >= 0 ? items.chargeAmount : "--" }}元/{{
                items.chargeTime ? items.chargeTime : "--"
              }}{{ items.chargeUnit }}</span
            >
          </li>
        </ul>
        <ul v-else>
          <li>
            <span>单次进出场</span>
            <span>{{ item.onceCharge ? item.onceCharge : "--" }}元</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="priceType === '按时长收费'" class="model_container">
      <div>
        <div class="title">{{ periodHours }}小时收费规则</div>
        <ul>
          <li v-for="item of timeRuleForm.priceUnitList" :key="item.id">
            <span
              >{{ item.fromTime >= 0 ? item.fromTime : "--" }}~{{
                item.toTime ? item.toTime : "--"
              }}{{ item.countUnit }}</span
            >
            <span
              >{{ item.chargeAmount >= 0 ? item.chargeAmount : "--" }}元/{{
                item.chargeTime ? item.chargeTime : "--"
              }}{{ item.chargeUnit }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="remark">
      <h6>备注:</h6>
      <p v-for="(item, index) of remarkList" :key="index">
        {{ index + 1 }}、{{ item }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "baseForm",
    "chargeForm",
    "detailForm",
    "priceType",
    "periodHours",
    "timeRuleForm",
  ],
  computed: {
    remarkList() {
      let list = [];
      if (this.chargeForm.freeIncluded) {
        list.push(`超出免费时长后，免费时长计费`);
      }
      if (this.chargeForm.onceDuration && this.chargeForm.onceCharge) {
        list.push(
          `前${this.chargeForm.onceDuration}${this.chargeForm.onceUnit}固定收费${this.chargeForm.onceCharge}元`
        );
      }
      if (this.chargeForm.freeLeaveMinutes) {
        list.push(
          `${this.chargeForm.freeLeaveMinutes}分钟内离场，超时加收停车费`
        );
      }
      if (this.chargeForm.priceComment) {
        list.push(`${this.chargeForm.priceComment}`);
      }
      return list;
    },
  },
};
</script>
<style lang="scss">
#rulePreview {
  width: 100%;
  $separation-distance: 2px;
  & > .model_container {
    .title {
      height: 42px;
      line-height: 42px;
      text-align: center;
      color: white;
      background-color: rgba(0, 206, 193, 0.78);
      margin-bottom: $separation-distance;
    }
    ul {
      li {
        display: flex;
        margin-bottom: $separation-distance;
        span {
          display: inline-block;
          box-sizing: border-box;
          color: white;
          font-size: 12px;
          width: 50%;
          height: 42px;
          line-height: 42px;
          text-align: center;
          background-color: rgba(0, 206, 193, 0.13);
          &:last-child {
            background-color: rgba(0, 206, 193, 0.25);
            margin-left: $separation-distance;
          }
        }
      }
    }
  }
  & > .remark {
    background-color: rgba(0, 206, 193, 0.29);
    box-sizing: border-box;
    padding: 15px;
    font-size: 12px;
    color: white;
    h6 {
      font-weight: initial;
    }
    p {
      margin-top: 8px;
    }
  }
}
</style>
